
import Space from '../component/assets/SpacePlanning.png'
import Layout from '../component/assets/Layouting.png'
import Build from '../component/assets/Built.png'
import Lightning from '../component/assets/Lighting.png'
import Acoustic from '../component/assets/Acoustics.png'
import Ceilling from '../component/assets/Ceilling.png'

import WestRoundedIcon from '@mui/icons-material/WestRounded';
import EastRoundedIcon from '@mui/icons-material/EastRounded';
import { Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation"
import Navbar from '../component/Navbar'

const Projects = () => {
  return (
    <>
    <Navbar/>
     <section className='mt-40 gap-4 container mx-auto'>
        <div className='flex flex-row justify-center'>
          <Swiper
            slidesPerView={1}
            modules={[ Navigation ]}
            navigation={{
              nextEl: '.button-next-slide',
              prevEl: '.button-prev-slide',
            }}
            breakpoints={{
              520: {slidesPerView: 2,
                    spaceBetween: -130},
              640: {slidesPerView: 2,
                    spaceBetween: -130},
              768: {slidesPerView: 2,
                    spaceBetween: -240},
              1024: {slidesPerView: 3,
                    spaceBetween: -200},
              1280: {slidesPerView: 3,
                    spaceBetween: -290},
              1536: {slidesPerView: 4,
                    spaceBetween: -190},
              }}
          >
            <SwiperSlide>
              <div className='flex flex-col items-center group transition-all'>
                <div>
                  <img className='h-72 group-hover:-translate-y-1 transition-all xs:h-52 sm:h-64 md:h-72 xl:h-96' src={Space} alt="Space Planning" />
                  <h4 className='font-sans text-sm font-medium pl-2 pt-4 group-hover:-translate-y-1 group-hover:text-orange-500 transition-all'>Space Planning</h4>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className='flex flex-col items-center group transition-all'>
                <div>
                  <img className='h-72 group-hover:-translate-y-1 transition-all xs:h-52 sm:h-64 md:h-72 xl:h-96' src={Layout} alt="Layout" />
                  <h4 className='font-sans text-sm font-medium pl-2 pt-4 group-hover:-translate-y-1 group-hover:text-orange-500 transition-all'>Layouting</h4>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className='flex flex-col items-center group transition-all'>
                <div>
                  <img className='h-72 group-hover:-translate-y-1 transition-all xs:h-52 sm:h-64 md:h-72 xl:h-96' src={Build} alt="Built-In/Custom Furniture" />
                  <h4 className='font-sans text-sm font-medium pl-2 pt-4 group-hover:-translate-y-1 group-hover:text-orange-500 transition-all'>Built-In/Custom Furniture</h4>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className='flex flex-col items-center group transition-all'>
                <div>
                  <img className='h-72 group-hover:-translate-y-1 transition-all xs:h-52 sm:h-64 md:h-72 xl:h-96' src={Lightning} alt="Lightning" />
                  <h4 className='font-sans text-sm font-medium pl-2 pt-4 group-hover:-translate-y-1 group-hover:text-orange-500 transition-all'>Lightning</h4>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className='flex flex-col items-center group transition-all'>
                <div>
                  <img className='h-72 group-hover:-translate-y-1 transition-all xs:h-52 sm:h-64 md:h-72 xl:h-96' src={Acoustic} alt="Acoustics" />
                  <h4 className='font-sans text-sm font-medium pl-2 pt-4 group-hover:-translate-y-1 group-hover:text-orange-500 transition-all'>Acoustics</h4>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className='flex flex-col items-center group transition-all'>
                <div>
                  <img className='h-72 group-hover:-translate-y-1 transition-all xs:h-52 sm:h-64 md:h-72 xl:h-96' src={Ceilling} alt="Ceilling Designs" />
                  <h4 className='font-sans text-sm font-medium pl-2 pt-4 group-hover:-translate-y-1 group-hover:text-orange-500 transition-all'>Ceilling Designs</h4>
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
        <div className='flex justify-center gap-12 mt-8'>
          <div className='button-prev-slide'><WestRoundedIcon className='hover:text-orange-500 transition-all' sx={{ fontSize: 30 }}/></div>
          <div className='button-next-slide'><EastRoundedIcon className='hover:text-orange-500 transition-all' sx={{ fontSize: 30 }}/></div>
        </div>
      </section>
      <footer className='mt-16 pb-8 text-center'>
        <p className='font-sans text-xs'>©2023 SWASTIK ENTERPRISES</p>
      </footer>
    </>
  )
}

export default Projects