import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './styles/index.css';
import './styles/App.css';
import './styles/Modern-normalize.css';
import Landing from './page/landing/Landing';
import Projects from './page/Projects';
import ProjectId from './page/ProjectId';

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<Landing />} />
          <Route path="/projects" element={<Projects />} />
          <Route path="/projects/:id" element={<ProjectId />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
