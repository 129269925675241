import React from 'react';
import { useParams } from 'react-router-dom';
import BaseThree from '../component/assets/Base 3.png';
import "swiper/css";
import "swiper/css/navigation";
import Navbar from '../component/Navbar';
import { RButton } from '../component/Button';

const ProjectId = () => {
  const { id } = useParams();
  console.log(id)

  // Assuming you have an object or array of projects to filter from
  // const projects = [{ id: '1', ... }, { id: '2', ... }, ...];
  // const project = projects.find(project => project.id === id);

  // Replace the following with your filtered project data
  const project = {
    title: 'Project Title',
    description: 'Project Description',
    imageUrl: BaseThree,
  };

  return (
    <>
      <Navbar />
      <section className='mt-24 container mx-auto xs:grid grid-cols-2'>
        <div id='project'>
          <img src={project.imageUrl} alt="Furniture" />
        </div>
        <div className='flex flex-col text-center mt-12 justify-center px-4 xs:text-left xs:mt-0 sm:px-8 lg:px-16 xl:px-28 2xl:px-36'>
          <h1 className='font-serif text-2xl tracking-wide font-medium mb-6 lg:text-3xl 2xl:text-4xl'>{id}</h1>
          <p className='font-sans text-xs mb-6 xs:mb-4 md:mb-6 lg:text-sm xl:text-base'>{id}</p>
          <div className='flex justify-center xs:justify-start'>
            <RButton displayText='VIEW MORE' />
          </div>
        </div>
      </section>
      <footer className='mt-16 pb-8 text-center'>
        <p className='font-sans text-xs'>©2023 SWASTIK ENTERPRISES</p>
      </footer>
    </>
  );
};

export default ProjectId;
